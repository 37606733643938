import React from 'react';
import styled from 'styled-components';

const Button = styled.a`
text-decoration:none;
display:flex;
justify-content:center;
align-items:center;
border-radius: var(--btn-radius);
border: 2.5px solid var(--black);
cursor:pointer;
&:hover h4{
    text-decoration:underline;
}
`
const Text = styled.h4`
    font-size: var(--text-font);
    color: var(--black);
    font-weight: 500;
    text-align:center;
    padding: 14px 30px;
`

const ButtonBorder = ({ className, children, onClick }) => <Button onClick={onClick} className={className}>
    <Text>{children}</Text>
</Button>

export default ButtonBorder