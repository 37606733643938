import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    width: 20px;
    color: var(--black);
    display:flex;
    justify-content:center;
    align-items:center;
`
const Icon = styled.svg`
    width: 100%;
    height: 100%;
`

const MobileIcon = ({ className, href }) => <Container target="_blank" href={href} className={className}>
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path xmlns="http://www.w3.org/2000/svg" d="M489.539,344.684l-55.519-39.657c-21.429-15.304-50.496-12.898-69.116,5.72l-32.533,32.533    c-41.692-29.544-134.086-121.926-163.655-163.652l32.533-32.532c18.622-18.622,21.027-47.689,5.721-69.116l-39.656-55.518    c-19.25-26.948-58.135-30.204-81.59-6.752L34.5,66.934c-46.034,46.033-45.997,108.331,0.103,170.922    c73.112,99.265,140.273,166.425,239.54,239.537c57.895,42.641,121.547,49.48,170.923,0.104l51.225-51.224    C519.709,402.854,516.525,363.959,489.539,344.684z M468.012,397.99l-51.225,51.224c-35.523,35.524-78.624,25.657-118.925-4.025    c-95.751-70.522-160.533-135.303-231.055-231.051c-29.671-40.286-39.559-83.391-4.026-118.923l51.226-51.225    c5.986-5.986,15.865-5.138,20.762,1.719l39.657,55.518c3.895,5.453,3.282,12.849-1.456,17.587l-43.294,43.292    c-5.454,5.455-7.285,13.559-4.706,20.829c13.791,38.861,165.273,190.316,204.096,204.092c7.269,2.582,15.374,0.749,20.828-4.706    l43.293-43.292c4.739-4.737,12.137-5.35,17.588-1.456l55.519,39.656C473.166,382.137,473.98,392.022,468.012,397.99z" />
    </Icon>
</Container>

export default MobileIcon