import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
//icons
import Form from "../components/form"
import MailIcon from "../assets/mail"
import MobileIcon from "../assets/mobile"
import InstagramIcon from "../assets/instagram"
import FormIcon from "../assets/form"


const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);`

const Layout = styled.div`
display:grid;
grid-template-columns: repeat(2,1fr);
grid-template-rows: repeat(2,max-content);
row-gap: 25px;
width: 100%;
max-width: var(--max-width);
@media (max-width: 768px) {
    grid-template-columns: 1fr;
}
`

const Moto = styled.h1`
align-self:start;
justify-self:start;
grid-column:1/3;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
max-width: 350px;
@media (max-width: 768px) {
    grid-column:1;
    }
`
// Contact list
const List = styled.div`
display:grid;
grid-template-columns: max-content 1fr;
grid-auto-flow: row;
grid-auto-rows: max-content;
row-gap: 25px;
column-gap: 10px;
`
const Textfield = styled.p`
align-self:start;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);
max-width: 250px;
grid-column:1/3;
`
const Media = styled.a`
text-decoration:none;
align-self:center;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);

&:hover{
    text-decoration:underline;
}
`
const Mail = styled(MailIcon)`
align-self:center;
justify-self:start;
`
const MobileButton = styled(FormIcon)`
@media (min-width: 768px) {
    display:none;
}
`
const MobileMedia = styled(Link)`
text-decoration:none;
align-self:center;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);

&:hover{
    text-decoration:underline;
}
@media (min-width: 768px) {
    display:none;
}
`



// Contact form

const FormFrame = styled(Form)`
@media (max-width: 768px) {
    display:none;
}
`


const Contact = ({ className }) => {


    return <Section className={className} id="contact">
        <Layout>
            <Moto>Interested?</Moto>
            <List>
                <Textfield>If you’d like to work together, contact me!</Textfield>
                <Mail href="mailto:contact@teuniemuah.nl" /><Media href="mailto:contact@teuniemuah.nl">contact@teuniemuah.nl</Media>
                <MobileIcon href="call:+31633701384" /><Media href="call:+31633701384">+31633701384</Media>
                <InstagramIcon href="https://www.instagram.com/teuniemuah/" /><Media href="https://www.instagram.com/teuniemuah/" target="_blank">@teuniemuah</Media>
                <MobileButton to="/form" /><MobileMedia to="/form">Send a form</MobileMedia>
            </List>
            <FormFrame />
        </Layout>
    </Section>
}
export default Contact