import React from 'react';
import styled from 'styled-components';
import logo from "../assets/logo.png"

const Container = styled.div`
    width: 50px;
    height: 50px;
    background-image: url(${logo});
    background-size: contain;
    background-repeat:no-repeat;
    background-position:center;
    border-radius: 50px;
`

const Logo = ({ className }) => <Container className={className}></Container>
export default Logo