import React from 'react';
import styled from 'styled-components';

const Section = styled.section`
position: relative;
display:flex;
justify-content:center;
padding: 10px var(--side-padding);
background: rgb(30,63,238);
background: linear-gradient(145deg, rgba(30,63,238,1) -15%, rgba(221,0,0,1) 50%, rgba(255,243,0,1) 115%);

`
const Layout = styled.div`
display:flex;
justify-content:center;
align-items:center;
`
const Text = styled.p`
    color:var(--white);
    font-size: var(--text-font);
`

const Success = () => <Section>
    <Layout>
        <Text>Form sent successfully!</Text>
    </Layout>
</Section>
export default Success