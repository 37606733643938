import React from 'react';
import styled from 'styled-components';
import Logo from "../items/logo"

const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);
background: rgb(30,63,238);
background: linear-gradient(145deg, rgba(30,63,238,1) -15%, rgba(221,0,0,1) 50%, rgba(255,243,0,1) 115%);

`

const Layout = styled.div`
display:flex;
justify-content:center;
align-items:center;
width: 100%;
max-width: var(--max-width);
height: 225px;
`
const LogoHolder = styled(Logo)`
width:175px;
height:175px;
filter: brightness(0) invert(1);
`

const Footer = () => <Section><Layout ><LogoHolder /></Layout></Section>

export default Footer