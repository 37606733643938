import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    width: 20px;
    color: var(--black);
    display:flex;
    justify-content:center;
    align-items:center;
`
const Icon = styled.svg`
    width: 100%;
    height: 100%;
`

const InstagramIcon = ({ className, href }) => <Container target="_blank" href={href} className={className}>
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path xmlns="http://www.w3.org/2000/svg" d="m392 0h-272c-66.167969 0-120 53.832031-120 120v272c0 66.167969 53.832031 120 120 120h272c66.167969 0 120-53.832031 120-120v-272c0-66.167969-53.832031-120-120-120zm80 392c0 44.113281-35.886719 80-80 80h-272c-44.113281 0-80-35.886719-80-80v-272c0-44.113281 35.886719-80 80-80h272c44.113281 0 80 35.886719 80 80zm-216-249c-62.308594 0-113 50.691406-113 113s50.691406 113 113 113 113-50.691406 113-113-50.691406-113-113-113zm0 186c-40.253906 0-73-32.746094-73-73s32.746094-73 73-73 73 32.746094 73 73-32.746094 73-73 73zm157.332031-205.332031c0 13.804687-11.191406 25-25 25-13.804687 0-25-11.195313-25-25 0-13.808594 11.195313-25 25-25 13.808594 0 25 11.191406 25 25zm0 0" />
    </Icon>
</Container>

export default InstagramIcon