import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Controller from "../components/controller"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import SwipeComp from "../components/swipe"

const Section = styled.section`
position: relative;
display:flex;
justify-content:center;
padding: 0 var(--side-padding);
@media (max-width: 768px) {
    padding: 50px var(--side-padding);
    padding-bottom: 100px;
}
`
const Layout = styled.div`
position: relative;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr max-content 1fr;
    row-gap: 25px;
    width: 100%;
    max-width: var(--max-width);
    height: 500px;
    z-index:1;
    
    @media (max-width: 768px) {
        height: auto;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4,max-content);
    }

`
const Background = styled.div`
    position: absolute;
    max-width: calc(var(--max-width) + 100px);
    width: calc(100% - 50px);
    height: 50%;
    bottom:0;
    background: #fff0fa;

    @media (max-width: 768px) {
        width:100%;
        height:100%;
    }
`
const Moto = styled.h1`
align-self:end;
justify-self:start;
    font-size: var(--head-font);
    font-weight: 700;
    color: var(--black);
    max-width: 350px;
`
const Textfield = styled.p`
align-self:start;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);
max-width: 340px;
`
const ControllerHolder = styled(Controller)`
align-self:start;
@media (max-width: 768px) {
    grid-row:2;
    padding: 15px 0;
    justify-self:center;
}
`
const ImageHolder = styled(TransitionGroup)`
position:relative;
grid-row: 1/4;
grid-column:1;
justify-self:start;
align-self:center;
width: 100%;
max-width: 350px;
height: 400px;
overflow:hidden;
@media (max-width: 768px) {
    grid-row: 1;
grid-column:1;
}
`
const Image = styled.div`
    height:100%;
    width:100%;
    background-image: url(${({ url }) => url});
    background-size:cover;
    background-position:center;
`

const link = ""
// const link = "https://jochemvandervalk.nl"
const path = "/api/images"
const AboutMe = ({ className }) => {
    const [slider, setSlider] = useState({ length: 0, current: 0 })
    const [textData, setTextData] = useState({})
    const [data, setData] = useState({})
    const [image, setImage] = useState(null)

    useEffect(() => {
        fetch(link + '/api/?filename=aboutme_images')
            .then(res => res.json())
            .then(doc => {
                setData(doc.data)
                const length = doc.data ? doc.data.length : 0
                setSlider({ length, current: 1 })
            })

        fetch(link + '/api/?filename=aboutme_text')
            .then(res => res.json())
            .then(doc => setTextData(doc.data || {}))
    }, [className])



    useEffect(() => {
        const slide = data
        const index = slider ? slider.current - 1 : 0
        if (slide && slide[index]) {
            const image = slide[index].refs[0]
            setImage(image)
        }
    }, [data, slider])

    return <Section id="aboutme">
        <Layout>
            <Moto>{textData.subject}</Moto>
            <Textfield>{textData.text}</Textfield>
            <ControllerHolder slider={slider} setSlider={setSlider} />
            <ImageHolder>
                <CSSTransition key={image} timeout={{ enter: 1100, exit: 1000 }} classNames={slider.change === 1 ? "main-gallery" : "reverse-main-gallery"}>
                    <div className="image-holder">
                        <SwipeComp slider={slider} setSlider={setSlider}>
                            <Image url={link + `${path}/${image}`} />
                        </SwipeComp></div>
                </CSSTransition>
            </ImageHolder>
        </Layout>
        <Background />
    </Section>
}
export default AboutMe