import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { useRouteMatch, Redirect } from "react-router-dom"
import UploadImg from '../components/uploadImg';
//containers

const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);
padding-top: 50px;
padding-bottom: 50vh;
`

const Layout = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-auto-flow:row;
grid-auto-rows: max-content;
grid-gap: 25px;
width: 100%;
max-width: var(--max-width);
`
const Header = styled.div`
    border-bottom: 2px var(--gray) solid;
    grid-column:1;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
`
const Title = styled.h1`
align-self:start;
justify-self:start;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
max-width: 350px;
`
const Form = styled.form`
display:grid;
grid-template-columns:1fr;
grid-auto-rows: max-content;
row-gap: 25px;
flex-direction:column;
& *{
    font-family: "Lato", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
}
`
const SaveBtn = styled.div`
justify-self:start;
padding: 15px 25px;
background: #34C759;
border-radius: var(--btn-radius);
color: var(--white);
border:none;
cursor:pointer;
&:hover{
    filter: brightness(0.9);
}
`
// TEXT EDIT INPUTS
const Moto = styled.input`
align-self:start;
justify-self:start;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
`
const Textfield = styled.textarea`
align-self:start;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);
max-width: 350px;
width: 100%;
height: 150px;
// resize: none;
`

//GALLERY EDIT INPUT
const GroupContainer = styled.div`
    display:grid;
    grid-template-columns: 50px;
    grid-auto-columns: max-content;
    grid-auto-flow: column;
    column-gap: 5px;
`
// selection
const Controller = styled.div`
    display:grid;
    grid-template-rows: repeat(3,1fr);
    grid-template-columns: 1fr;
`
const Number = styled.h4`
    font-size: var(--text-font);
    justify-self:center;
    align-self:center;
    grid-row:2;
    `
const ArrowUp = styled.svg`
    justify-self:center;
    align-self:center;
    width: 15px;
    grid-row:1;
    cursor:pointer;
`
const ArrowDown = styled(ArrowUp)`
    transform: rotate(180deg);
    grid-row:3;
`
// imgs 
const Image = styled.div`
position:relative;
width: 150px;
height: 150px;
background: url(${({ url }) => url});
background-size:contain;
background-position:center;
background-repeat:no-repeat;
`
const AddGroupBtn = styled.div`
justify-self:start;
padding: 15px 25px;
background: #007AFF;
border-radius: var(--btn-radius);
color: var(--white);
border:none;
cursor:pointer;
&:hover{
    filter: brightness(0.9);
}
`
const AddImageBtn = styled.div`
justify-self:start;
padding: 7.5px 15px;
font-size: 12.5px;
background: #007AFF;
justify-self:center;
    align-self:center;
border-radius: var(--btn-radius);
color: var(--white);
border:none;
cursor:pointer;
&:hover{
    filter: brightness(0.9);
}
`
const DeleteImageBtn = styled.div`
position:absolute;
top:0;
right:0;
justify-self:start;
padding: 7.5px 15px;
font-size: 12.5px;
background: #FF3B30;
justify-self:center;
align-self:center;
border-radius: var(--btn-radius);
color: var(--white);
border:none;
cursor:pointer;
&:hover{
    filter: brightness(0.9);
}
`
const DeleteGroupBtn = styled(DeleteImageBtn)`
position:relative;
`

const base = ""
// const base = "https://jochemvandervalk.nl"
const Edit = ({ loggedIn }) => {
    const [data, setData] = useState({})
    const [metadata, setMetadata] = useState({})
    const [showUploader, setShowUploader] = useState(false)
    const { params: { part } } = useRouteMatch("/admin/:part")

    useEffect(() => {
        if (loggedIn) {
            fetch(base + `/api/?filename=${part}`)
                .then(res => res.json())
                .then(doc => {
                    setData(doc.data || {})
                    setMetadata(doc.metadata || {})
                })
        }
    }, [loggedIn])

    const onChange = ({ target: { name, value } }) => {
        const obj = {}
        obj[name] = value
        setData(e => ({ ...e, ...obj }))
    }
    //group edit
    const addGroup = () => setData(e => [{ refs: [] }, ...e])
    const moveGroup = (i, move) => {
        const copyData = [...data]
        copyData[i] = data[i + move]
        copyData[i + move] = data[i]
        setData(copyData)
    }
    const removeGroup = (i) => {
        let confirmed = window.confirm('Weet je het zeker?')
        if (!confirmed) return
        const copyData = [...data]
        copyData.splice(i, 1);
        setData(copyData)
    }
    //img edit
    const addImgToGroup = (name, i) => {
        const copyData = [...data]
        copyData[i].refs.push(name)
        setData(copyData)
    }
    const removeImgFromGroup = (i, j) => {
        let confirmed = window.confirm('Weet je het zeker?')
        if (!confirmed) return
        const copyData = [...data]
        copyData[i].refs.splice(j, 1);
        setData(copyData)
    }

    const StoreData = e => {
        e.preventDefault()
        let confirmed = window.confirm('Weet je het zeker?')
        if (!confirmed) return

        let packet = {}
        packet.data = data
        packet.metadata = metadata
        const packetDeploy = JSON.stringify(packet)
        const { username, password } = loggedIn
        fetch(`/api/editfile.php?filename=${metadata.link}&data=${packetDeploy}&username=${username}&password=${password}`, { method: 'POST' })
            .then(res => res.json())
            .then(doc => {
                console.log(doc, 'Save successed!')
            })
    }

    return <Section method="post">
        {!loggedIn && <Redirect to="/login" />}
        {showUploader !== false && <UploadImg loggedIn={loggedIn} i={showUploader} setShow={setShowUploader} addedImg={addImgToGroup} />}
        <Layout>
            <Header>
                <Title>{metadata && metadata.link}</Title>
            </Header>

            {/* Edit text data */}
            {metadata.type === 'text' && <Form>
                <SaveBtn onClick={StoreData}>Opslaan</SaveBtn>
                <Moto name="subject" onChange={onChange} value={data.subject} />
                <Textfield name="text" onChange={onChange} value={data.text} />
            </Form>}

            {/* Edit gallery */}
            {metadata.type === 'images' && <Form>

                <SaveBtn onClick={StoreData}>Opslaan</SaveBtn>

                {metadata.mutable && <AddGroupBtn onClick={addGroup}>Groep toevoegen</AddGroupBtn>}

                {data.map(({ refs }, i, all) => <GroupContainer key={i}>


                    <Controller>
                        {i > 0 && <ArrowUp onClick={() => moveGroup(i, -1)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path xmlns="http://www.w3.org/2000/svg" d="M286.935,197.286L159.028,69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.286   C1.807,200.9,0,205.184,0,210.132s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428h255.813   c4.949,0,9.233-1.811,12.848-5.428c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.9,286.935,197.286z" /></ArrowUp>}
                        <Number>{i + 1}</Number>
                        {i < all.length - 1 && <ArrowDown onClick={() => moveGroup(i, 1)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362"><path xmlns="http://www.w3.org/2000/svg" d="M286.935,197.286L159.028,69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.286   C1.807,200.9,0,205.184,0,210.132s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428h255.813   c4.949,0,9.233-1.811,12.848-5.428c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.9,286.935,197.286z" /></ArrowDown>}
                    </Controller>


                    {refs && refs.map((url, j) => <Image key={url} url={base + "/api/images/" + url}>
                        <DeleteImageBtn onClick={() => removeImgFromGroup(i, j)}>Verwijder</DeleteImageBtn>
                    </Image>)}

                    {refs.length === 0 && <DeleteGroupBtn onClick={() => removeGroup(i)}>Verwijder groep</DeleteGroupBtn>}
                    <AddImageBtn onClick={() => setShowUploader(i)}>Voeg foto toe</AddImageBtn>

                </GroupContainer>)}
            </Form>}
        </Layout>
    </Section>
}
export default Edit