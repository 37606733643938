import React from 'react';
import styled from 'styled-components';

const Section = styled.div`
    position:fixed;
    top:0;
    right:0;
    left:0;
    bottom:0;
    z-index:10;
    width: calc(100% - var(--side-padding) * 2);
    height:100%;
    background:var(--white);
    display:grid;
    grid-template-columns:1fr;
    padding: 0 var(--side-padding);
    grid-template-rows: 175px repeat(4,1fr) 175px;

    & div{
        width: 20px;
        height:20px;
        background:var(--black);
    }

    @media (min-width: 768px) {
        display:none;
    }
`

const CloseIcon = styled.svg`
width: 20px;
justify-self:end;
align-self:start;
padding: 50px 15px;
`

const Item = styled.a`
    text-decoration:none;
    color: var(--black);
    font-size: 25px;
    font-weight: 700;
    cursor:pointer;
    justify-self:center;
    align-self:center;
    &:hover{
        text-decoration:underline;
    }
`
const items = [{ name: 'Home', scroll: 'home' },
{ name: 'Portfolio', scroll: 'portfolio' },
{ name: 'About me', scroll: 'aboutme' },
{ name: 'Contact', scroll: 'contact' },]

const Nav = ({ className, setNav }) => {

    const scrollTo = (id) => {
        const elm = document.getElementById(id)
        if (!elm) return
        const offsetTop = elm.offsetTop
        window.scrollTo(0, offsetTop)
        setNav(false)
    }

    return <Section className={className}>
        <CloseIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512.001" onClick={() => setNav(false)}>
            <path xmlns="http://www.w3.org/2000/svg" d="M284.284,256L506.142,34.142c7.811-7.81,7.811-20.474,0-28.284c-7.811-7.811-20.474-7.811-28.284,0L256,227.716    L34.142,5.858c-7.811-7.811-20.474-7.811-28.284,0c-7.811,7.81-7.811,20.474,0,28.284L227.716,256L5.858,477.858    c-7.811,7.811-7.811,20.474,0,28.284c7.81,7.81,20.473,7.811,28.284,0L256,284.284l221.858,221.858    c7.81,7.81,20.473,7.811,28.284,0c7.811-7.811,7.811-20.474,0-28.284L284.284,256z" />
        </CloseIcon>
        {items.map(({ name, scroll }) => <Item key={name} onClick={() => scrollTo(scroll)}>{name}</Item>)}
    </Section>
}

export default Nav