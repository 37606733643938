import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Link, Route } from "react-router-dom"

const Section = styled.section`
display:flex;
justify-content:center;
padding: 100px var(--side-padding);
height: calc(100vh - 200px);
background:var(--black);
overflow-x:auto;
`
const FullSection = styled(Section)`
background: #0d0d0d9f;
padding:0;
height: 100vh;
width: 100%;
position: fixed;
z-index: 10;
`

const Layout = styled.div`
display:grid;

grid-auto-flow:row;
grid-template-columns: repeat(auto-fit, minmax(250px,1fr));
grid-auto-rows: max-content;
grid-gap: 25px;

width: 100%;
max-width: var(--max-width);
`
const ImgLink = styled(Link)`
    justify-self:stretch;
    align-self:stretch;
`
const Image = styled.img`
    width:100%;
`
const FullImage = styled.div`
    height: 100%;
    width: 100%;
    background: url(${({ url }) => url});
    background-repeat:no-repeat;
    background-position:center;
    background-size:contain;
`
const Moto = styled.h1`
align-self:start;
justify-self:start;
font-size: var(--head-font);
font-weight: 700;
color: var(--white);
`
const Nav = styled.div`
    display:grid;
    grid-template-columns:1fr;
    grid-auto-rows: max-content;
`
const ReturnLink = styled(Link)`
    text-decoration:none;
    display:flex;
    flex-direction:row;
    align-items:center;
    justify-self:start;
    &:hover h3,&:active h3{
        text-decoration: underline;
        // border-bottom: 0.5px var(--white) solid;
    }
`
const ReturnIcon = styled.svg`
    transform:rotate(-90deg) scaleY(1.5);
    fill: var(--white);
    height: 11px;
    margin-right: 5px;
`
const ReturnText = styled.h3`
    color: var(--white);
    font-size: var(--text-font);
`

const FullScreen = ({ match: { params: { img } } }) => <FullSection>
    <FullImage url={"/api/images/" + img} />
</FullSection>

const Portfolio = ({ className }) => {
    const [data, setData] = useState([])

    useEffect(() => {
        fetch('/api/?filename=portfolio_images')
            .then(res => res.json())
            .then(doc => {
                const portfolio = doc.data
                setData(portfolio)
            })
    }, [className])

    return <>
        <Route path="/portfolio/:img" render={({ match }) => <FullScreen match={match} />}></Route>
        <Section>
            <Layout>
                <Nav>
                    <Moto>Portfolio</Moto>
                    <ReturnLink to="/">
                        <ReturnIcon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 292.362 292.362">
                            <path xmlns="http://www.w3.org/2000/svg" d="M286.935,197.286L159.028,69.379c-3.613-3.617-7.895-5.424-12.847-5.424s-9.233,1.807-12.85,5.424L5.424,197.286   C1.807,200.9,0,205.184,0,210.132s1.807,9.233,5.424,12.847c3.621,3.617,7.902,5.428,12.85,5.428h255.813   c4.949,0,9.233-1.811,12.848-5.428c3.613-3.613,5.427-7.898,5.427-12.847S290.548,200.9,286.935,197.286z"></path>
                        </ReturnIcon>
                        <ReturnText>Home</ReturnText>
                    </ReturnLink>
                </Nav>
                {data && data.map(({ refs }) =>
                    refs && refs.map(ref => <ImgLink key={ref} to={`/portfolio/${ref}`}  >
                        <Image src={"/api/images/" + ref} />
                    </ImgLink>))}
            </Layout>
        </Section></>
}
export default Portfolio