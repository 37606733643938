import React, { useState } from 'react';
import styled from "styled-components"


const Container = styled.form`
    display:grid;
    grid-template-columns: 1fr 100px;
    grid-template-rows: 50px 200px;
    grid-gap:10px;

    & textarea,
    & input.text,
    & input[type="text"],
    & input[type="button"],
    & input[type="email"],
    & input[type="submit"],
    & *{
        border: 2.5px solid var(--black);
        border-radius: var(--btn-radius);
        background: var(--white);
        font-size: var(--text-font);
        align-self:stretch;
        justify-self:stretch;
        color:var(--black);
    }

    & input[type="submit"]{
        display:flex;
justify-content:center;
align-items:center;
height:100%;
background: transparent;
cursor:pointer;
grid-row:1;
grid-column:2;
    }
`
const Email = styled.input`
    padding-left: 10px;
`
const Submit = styled.input`
display:flex;
justify-content:center;
align-items:center;
cursor:pointer;
grid-row:1;
grid-column:2;
`
const Button = styled.div`
display:flex;
justify-content:center;
align-items:center;
cursor:default;
grid-row:1;
grid-column:2;
opacity: 0.5;
`
const Textarea = styled.textarea`
padding: 10px;
resize:none;
grid-column:1/3;
font-family: "Lato", sans-serif;
`

const Form = ({ className }) => {

    const [form, setForm] = useState({ email: false, message: false })

    const change = ({ target: { name, value } }) => {
        const data = {}
        data[name] = value
        setForm(e => ({ ...e, ...data }))
    }

    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    const isCompleet = () => validateEmail(form.email) && form.message

    return <Container action="action_script.php" method="post" className={className}>
        <Email onChange={change} type="email" name="email" placeholder="Your e-mail address" />
        <Textarea onChange={change} name="message" placeholder="Your message" />
        {isCompleet() ? <Submit type="submit" name="submit" value="Send" /> : <Button>Send</Button>}
    </Container>
}
export default Form