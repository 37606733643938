import React, { useRef, useState } from 'react';
import styled from 'styled-components';

const Section = styled.section`
    position:fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    background: rgba(12,12,12,0.5);
    display:flex;
    justify-content:center;
    align-items:center;
    z-index: 10;
`
const Container = styled.div`
    display:grid;
    grid-template-rows:repeat(3,1fr);
    grid-template-columns:1fr;
    background: var(--white);
    width: 100%;
    max-width:600px;
    height: 400px;
    border-radius: var(--btn-radius);
`
const Close = styled.svg`
    width:20px;
    fill:var(--black);
    margin: 20px;
    cursor:pointer;
    justify-self:end;
    align-self:start;
`
const FilePicker = styled.input`
    justify-self:center;
    align-self:center;
`
const UploadImgBtn = styled.div`
justify-self:start;
padding: 15px 25px;
background: #007AFF;
justify-self:center;
    align-self:center;
border-radius: var(--btn-radius);
color: var(--white);
border:none;
cursor:pointer;
&:hover{
    filter: brightness(0.9);
}
`
const UploadImg = ({ setShow, addedImg, loggedIn, i }) => {
    const fileRef = useRef(null)
    const [loading, setLoading] = useState(false)

    const UploadFile = () => {
        const input = fileRef.current
        const file = input.files[0]
        if (!file) return
        setLoading('Loading...')
        const formData = new FormData()
        formData.append('file', file)

        const { username, password } = loggedIn
        fetch(`/api/upload.php/?username=${username}&password=${password}`, {
            method: 'POST',
            body: formData,
        }).then(res => res.json()).then((data) => {
            if (data.success) {
                addedImg(file.name, i)
                setLoading(false)
                setShow(false)
            } else if (data.error) {
                setLoading(data.error)
            } else {
                setLoading("Er ging iets mis")
            }
        })
    }

    return <Section>
        <Container>
            <Close onClick={() => setShow(false)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 339.177 339.177">
                <path xmlns="http://www.w3.org/2000/svg" d="M247.244,169.59l83.938-83.938c5.332-5.327,7.994-11.798,7.994-19.414c0-7.614-2.669-14.084-7.994-19.414L292.355,7.993   C287.026,2.665,280.556,0,272.944,0c-7.617,0-14.085,2.665-19.417,7.993L169.59,91.931L85.651,7.993   C80.325,2.665,73.854,0,66.237,0c-7.611,0-14.083,2.665-19.414,7.993L7.994,46.824C2.667,52.15,0,58.624,0,66.238   c0,7.616,2.664,14.084,7.994,19.414l83.937,83.938L7.994,253.528C2.667,258.859,0,265.327,0,272.945   c0,7.61,2.664,14.082,7.994,19.41l38.83,38.828c5.33,5.332,11.803,7.994,19.414,7.994c7.616,0,14.084-2.669,19.414-7.994   l83.939-83.938l83.944,83.938c5.328,5.332,11.793,7.994,19.417,7.994c7.611,0,14.082-2.669,19.411-7.994l38.82-38.828   c5.332-5.324,7.994-11.8,7.994-19.41c0-7.618-2.662-14.086-7.994-19.417L247.244,169.59z" />
            </Close>
            <FilePicker ref={fileRef} type="file" accept="image/png, image/jpeg,image/jpg" />
            <UploadImgBtn onClick={!loading ? UploadFile : () => console.log('loading..')}>{loading || "Upload"}</UploadImgBtn>
        </Container>
    </Section>
}
export default UploadImg