import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import { CSSTransition, TransitionGroup } from "react-transition-group"
import SwipeComp from "./swipe"

const Section = styled.section`
position:relative;
// background:#f7f7f7;
height: 600px;
@media (max-width: 768px) {
    display:flex;
    height: 475px;
    justify-content:center;
  }
`
const MainHolder = styled(TransitionGroup)`
    position:absolute;
    width: calc(100% - 50px);
    overflow:hidden;
    height: 550px;
    display:flex;
    justify-content:center;
    align-items:center;
    @media (max-width: 768px) {   
        width: 100%;
        height: 400px;
    }
    @media (min-width: 768px) {
        right:0;
      }
    `
const ChildHolder = styled(TransitionGroup)`
    position:absolute;
    overflow:hidden;
    bottom:0;

    width: 250px;
    height: 150px;
    @media (min-width: 768px) {
        height: 175px;
    left: calc(250px / -2 + 50px);
  }
`
const Image = styled.div`
    height:100%;
    width:100%;
    background-image: url(${({ url }) => url});
    background-size:cover;
    background-position:center;
`
const link = ""
// const link = "https://jochemvandervalk.nl"
const path = "/api/images"
const Gallery = ({ className, slider, setSlider }) => {
    const [data, setData] = useState({})
    const [main, setMain] = useState(null)
    const [child, setChild] = useState(null)

    useEffect(() => {
        fetch(link + '/api/?filename=portfolio_images')
            .then(res => res.json())
            .then(doc => {
                const portfolio = doc.data
                setData(portfolio)
                const length = portfolio ? portfolio.length : 0
                setSlider({ length, current: 1 })
            })
    }, [className])

    useEffect(() => {
        const slide = data
        const index = slider ? slider.current - 1 : 0
        if (slide && slide[index]) {
            const images = slide[index].refs
            setMain(images[0])
            setChild(images[1] || null)
        }
    }, [data, slider])


    return <Section className={className}>
        <MainHolder>
            <CSSTransition key={main} timeout={{ enter: 1100, exit: 1000 }} classNames={slider.change === 1 ? "main-gallery" : "reverse-main-gallery"}>
                <div className="image-holder">
                    <Link to="/portfolio">
                        <SwipeComp slider={slider} setSlider={setSlider}>
                            <Image url={link + `${path}/${main}`} />
                        </SwipeComp>
                    </Link>
                </div>
            </CSSTransition></MainHolder>
        <ChildHolder><CSSTransition key={child} timeout={800} classNames={slider.change === 1 ? "child-gallery" : "reverse-child-gallery"}><div className="image-holder"> <Image url={link + `${path}/${child}`} /></div></CSSTransition></ChildHolder>
    </Section>
}
export default Gallery