import React from 'react';
import styled from 'styled-components';
import { Link, Redirect } from "react-router-dom"


const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);
padding-top: 50px;
padding-bottom: 50vh;
`

const Layout = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-auto-flow:row;
grid-auto-rows: max-content;
grid-gap: 25px;
width: 100%;
max-width: var(--max-width);
`
const Header = styled.div`
    border-bottom: 2px var(--gray) solid;
    grid-column:1;
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 1fr;
`
const Moto = styled.h1`
align-self:start;
justify-self:start;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
max-width: 350px;
`


const LinkButton = styled(Link)`
    justify-self:start;
    padding: 10px 15px;
    font-size: 20px;
    padding: 10px 15px;
    color: var(--black);
    background: var(--gray);

`


const parts = [
    'hero_text', 'hero_images',
    'portfolio_text', 'portfolio_images',
    'aboutme_text', 'aboutme_images']

const Editor = ({ loggedIn }) => <Section>
    {loggedIn ?
        <Layout>
            <Header>
                <Moto>Website editor</Moto>
            </Header>

            {parts.map((link) => <LinkButton key={link} to={`/admin/${link}`}>{link}</LinkButton>)}
        </Layout> : <Redirect to="/login" />

    }
</Section>

export default Editor