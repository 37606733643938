import React from 'react';
import styled from 'styled-components';

const Container = styled.a`
    width: 20px;
    color: var(--black);
    display:flex;
    justify-content:center;
    align-items:center;
`
const Icon = styled.svg`
    width: 100%;
    height: 100%;
`

const MailIcon = ({ className, href }) => <Container target="_blank" href={href} className={className}>
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path xmlns="http://www.w3.org/2000/svg" d="M462,86H50c-27.354,0-50,22.214-50,50v240c0,27.57,22.43,50,50,50h412c27.57,0,50-22.43,50-50V136    C512,108.581,489.681,86,462,86z M423.248,126L256,232.302L88.752,126H423.248z M472,376c0,5.514-4.486,10-10,10H50    c-5.514,0-10-4.486-10-10V142.41l205.271,130.469c6.545,4.161,14.91,4.162,21.457,0L472,142.41V376z" />
    </Icon>
</Container>

export default MailIcon