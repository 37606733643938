import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
//componets
import Gallery from "../components/gallery"
import Controller from "../components/controller"



const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);
@media (max-width: 768px){
    padding-bottom: 50px;
}
`
const Layout = styled.div`
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: repeat(4,max-content);
    row-gap: 25px;
    width: 100%;
    max-width: var(--max-width);

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
      }
`
const Moto = styled.h1`
align-self:start;
justify-self:start;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
max-width: 350px;
@media (min-width: 768px) {
    padding-top: 75px;
}
`
const Textfield = styled.p`
align-self:start;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);
max-width: 340px;
`

const GalleryHolder = styled(Gallery)`
align-self:start;
justify-self:stretch;
grid-column:2;
grid-row: 1/5;

@media (max-width: 768px) {
    grid-column:1;
    grid-row: 1;
  }
`

const ControllerHolder = styled(Controller)`
@media (max-width: 768px) {
    grid-column:1;
    grid-row: 2;
    justify-self:center;
    padding: 15px 0;
  }
`

const link = ""
// const link = "https://jochemvandervalk.nl"
const Portfolio = ({ className }) => {
    const [slider, setSlider] = useState({ length: 0, current: 0 })
    const [data, setData] = useState({})


    useEffect(() => {
        fetch(link + '/api/?filename=portfolio_text')
            .then(res => res.json())
            .then(doc => setData(doc.data || {}))
    }, [className])


    return <Section className={className} id="portfolio">
        <Layout>
            <Moto>{data.subject}</Moto>
            <Textfield>{data.text}</Textfield>

            <ControllerHolder slider={slider} setSlider={setSlider} />

            <GalleryHolder slider={slider} setSlider={setSlider} />
        </Layout>
    </Section>
}
export default Portfolio