import React, { useState } from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import HomePage from './screens/homepage';
import FormPage from './screens/form';
import Editor from './screens/editor';
import Edit from './screens/edit';
import Login from './screens/login';
import Portfolio from './screens/portfolio';


function App() {
  const [loggedIn, setLoggedIn] = useState(false)

  return (
    <BrowserRouter>
      <Switch>
        {/* Admin */}
        <Route exact path="/admin/:part"><Edit loggedIn={loggedIn} /></Route>
        <Route exact path="/admin/"><Editor loggedIn={loggedIn} /></Route>
        <Route exact path="/login/"><Login loggedIn={loggedIn} setLoggedIn={setLoggedIn} /></Route>
        {/* Client */}
        <Route exact path="/form"><FormPage /></Route>
        <Route path="/portfolio/"><Portfolio /></Route>
        <Route><HomePage /></Route>
      </Switch>
    </BrowserRouter>
  );
}

export default App;
