import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

const Section = styled.section`
    display:flex;
    width: 100%;
    height: 100vh;
    justify-content:center;
    align-items:center;
`

const LogForm = styled.form`
    display:flex;
    justify-content:center;
    flex-direction:column;

    & *{
        margin: 5px 0;
    }
`

const LoginScreen = ({ setLoggedIn }) => {
    let history = useHistory()

    const login = (e) => {
        e.preventDefault()
        const form = e.target.form
        const username = form[0].value
        const password = form[1].value
        fetch(`/api/login/?username=${username}&password=${password}`)
            .then(res => res.json())
            .then(doc => {
                if (doc.login) {
                    setLoggedIn({ password, username } || false)
                    console.log(history)
                    history.replace('/admin')
                }
            })
    }

    return <Section>
        <LogForm>
            <h1>Login</h1>
            <input type="text" placeholder="username" />
            <input type="password" placeholder="wachtwoord" />
            <input type="button" onClick={login} value="Login" />
        </LogForm>
    </Section>
}

export default LoginScreen