import React from 'react';
import styled from 'styled-components';
import { Link } from "react-router-dom"
import Form from "../components/form"


const Section = styled.section`
display:flex;
justify-content:center;
padding: 0 var(--side-padding);`

const Layout = styled.div`
display:grid;
grid-template-columns: 1fr;
grid-template-rows: 75px max-content 1fr;
row-gap: 25px;
width: 100%;
max-width: var(--max-width);
`

const Return = styled(Link)`
text-decoration:none;
justify-self:start;
align-self:center;
display:flex;
align-items:center;
flex-direction:row;
&:hover,&:active{
    color: var(--black);
    text-decoration:underline;
}
`
const Container = styled.svg`
fill: var(--black);
width: 22.5px;
display:flex;
align-items:center;
justify-content:center;
`
const Text = styled.p`
    font-size: var(--text-font);
    color: var(--black);
    margin-left: 10px;
`
const Moto = styled.h1`
align-self:start;
justify-self:start;
grid-column:1/3;
font-size: var(--head-font);
font-weight: 700;
color: var(--black);
max-width: 350px;
`
const FormFrame = styled(Form)`
    justify-self:start;
    align-self:center;
    width: 100%;
    max-width: 500px;
`

const FormPage = ({ }) => {

    return <Section><Layout>
        <Return to="/" >
            <Container xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                <path xmlns="http://www.w3.org/2000/svg" d="M492,236H68.442l70.164-69.824c7.829-7.792,7.859-20.455,0.067-28.284c-7.792-7.83-20.456-7.859-28.285-0.068    l-104.504,104c-0.007,0.006-0.012,0.013-0.018,0.019c-7.809,7.792-7.834,20.496-0.002,28.314c0.007,0.006,0.012,0.013,0.018,0.019    l104.504,104c7.828,7.79,20.492,7.763,28.285-0.068c7.792-7.829,7.762-20.492-0.067-28.284L68.442,276H492    c11.046,0,20-8.954,20-20C512,244.954,503.046,236,492,236z" />
            </Container>
            <Text>Home page</Text>
        </Return>
        <Moto>
            Contact form
        </Moto>
        <FormFrame />
    </Layout></Section>
}
export default FormPage