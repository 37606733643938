import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
`

const SwipeComp = ({ children, slider, setSlider }) => {
    let startY = null

    const editPosition = (i) => setSlider(e => ({ ...e, current: e.current += i, change: i }))
    const canLower = () => slider && slider.current > 1
    const canHigher = () => slider && slider.length > slider.current

    const startTouch = ({ changedTouches }) => startY = changedTouches[0].clientX
    const endTouch = ({ changedTouches }) => {
        const { clientX } = changedTouches[0]
        const offset = clientX - startY
        if (offset > 25 && canLower()) editPosition(-1)
        else if (offset < -25 && canHigher()) editPosition(1)
    }

    return <Container onTouchStart={startTouch} onTouchEnd={endTouch}>{children}</Container>
}
export default SwipeComp