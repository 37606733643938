import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled(Link)`
    width: 20px;
    color: var(--black);
    display:flex;
    justify-content:center;
    align-items:center;
`
const Icon = styled.svg`
    width: 100%;
    height: 100%;
`

const FormIcon = ({ className, href, to }) => <Container to={to} target="_blank" href={href} className={className}>
    <Icon xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path xmlns="http://www.w3.org/2000/svg" d="M426,0H187.91c-5.254,0-10.349,2.141-13.993,5.71L42.007,134.883c-3.863,3.784-6.007,9.035-6.007,14.29v313.45    C36,489.849,58.43,512,86,512h340c27.57,0,50-22.151,50-49.377V49.377C476,22.151,453.57,0,426,0z M167.91,67.578v61.595h-62.9    L167.91,67.578z M436,462.623c0,5.171-4.486,9.377-10,9.377H86c-5.514,0-10-4.207-10-9.377v-293.45h111.91    c11.046,0,20-8.954,20-20V40H426c5.514,0,10,4.207,10,9.377V462.623z" />
    </Icon>
</Container>

export default FormIcon