import React from 'react';
import styled from 'styled-components';

import Home from '../panels/home';
import Portfolio from '../panels/portfolio';
import AboutMe from '../panels/aboutme';
import Contact from '../panels/contact';
import Footer from '../panels/footer';
import { Route } from 'react-router-dom';
import Success from '../components/success';


const Layout = styled.div`
  display:grid;
  grid-template-columns: 1fr;
  grid-auto-flow:row;
  grid-auto-rows: max-content;
  row-gap: 100px;
`

const HomePage = () => <Layout>
  <Route exact path="/success"><Success /></Route>
  <Home />
  <Portfolio />
  <AboutMe />
  <Contact />
  <Footer />
</Layout>

export default HomePage