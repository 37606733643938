import React from 'react';
import styled, { keyframes } from 'styled-components';
import Logo from '../items/logo'

const Section = styled.header`
    display:grid;
    // height: 100px;
    grid-template-rows:1fr;
    grid-auto-flow:column;
    grid-auto-columns: max-content;
    column-gap: 60px;
    @media (max-width: 768px) {
        width:100%;
        column-gap: 0px;
        grid-template-columns: repeat(2,1fr);
    }
`
const BurgerIcon = styled.svg`
    width: 20px;
    justify-self:end;
    align-self:center;
    padding: 20px;
    @media (min-width: 768px) {
        display:none;
    }
`
const Item = styled.a`
    text-decoration:none;
    color: var(--black);
    font-size: 18px;
    font-weight: 700;
    cursor:pointer;
    justify-self:center;
    align-self:center;
    &:hover{
        text-decoration:underline;
    }
    @media (max-width: 768px) {
        display:none;
    }
`
const fadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`
const LogoIcon = styled(Logo)`
    width:125px;
    height:125px;
    align-self:center;
    justify-self:start;
    animation: ${fadeIn} 600ms 0ms ease-out forwards;
`

const items = [{ name: 'Home', scroll: 'home' },
{ name: 'Portfolio', scroll: 'portfolio' },
{ name: 'About me', scroll: 'aboutme' },
{ name: 'Contact', scroll: 'contact' },]

const scrollTo = (id) => {
    const elm = document.getElementById(id)
    if (!elm) return
    const offsetTop = elm.offsetTop
    window.scrollTo(0, offsetTop)
}

const Header = ({ className, setNav }) => {

    return <Section className={className}>
        <LogoIcon />
        {items.map(({ name, scroll }) => <Item key={name} onClick={() => scrollTo(scroll)}>{name}</Item>)}
        <BurgerIcon onClick={() => setNav(true)} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
            <path d="M492,236H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,236,492,236z" />
            <path d="M492,76H20C8.954,76,0,84.954,0,96s8.954,20,20,20h472c11.046,0,20-8.954,20-20S503.046,76,492,76z" />
            <path d="M492,396H20c-11.046,0-20,8.954-20,20c0,11.046,8.954,20,20,20h472c11.046,0,20-8.954,20-20    C512,404.954,503.046,396,492,396z" />
        </BurgerIcon>
    </Section>
}
export default Header