import React, { useState, useEffect } from 'react';
import styled, { keyframes } from 'styled-components';
//componets
import Header from '../components/header'
import Logo from '../items/logo'
import ButtonBorder from '../items/button_border'
import HeroGallery from "../components/heroGallery"
import Nav from "../components/nav"

const fadeIn = keyframes`
    from {
        opacity:0;
    }
    to {
        opacity:1;
    }
`
const Section = styled.section`
    display:flex;
    justify-content:center;
    padding: 0 var(--side-padding);
    @media (max-width: 768px) {
        align-items:center;
        // height: calc(100vh - 50px);
    }
`
const Layout = styled.div`
    display:grid;
    grid-template-columns: repeat(2,1fr);
    grid-template-rows: 200px repeat(5,max-content);
    row-gap: 35px;
    width: 100%;
    max-width: var(--max-width);

    @media (max-width: 768px) {
        padding-top:50px;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(4,max-content);
    }
`
const Navigation = styled(Header)`
grid-column:1/3;
grid-row: 1;
align-self:center;
justify-self:start;
@media (max-width: 768px) {
    grid-column:1;
}
`
const LogoIcon = styled(Logo)`
    width:125px;
    height:125px;
    align-self:center;
    justify-self:start;
    animation: ${fadeIn} 600ms 0ms ease-out forwards;
`
const Moto = styled.h1`
align-self:center;
justify-self:start;
    font-size: var(--head-font);
    font-weight: 700;
    color: var(--black);
    max-width: 350px;
    opacity:0;
    animation: ${fadeIn} 600ms 100ms ease-out forwards;
`
const Textfield = styled.p`
align-self:center;
justify-self:start;
font-size: var(--text-font);
font-weight: 500;
color: var(--black);
max-width: 305px;
opacity:0;
animation: ${fadeIn} 600ms 100ms ease-out forwards;
`
const Contact = styled(ButtonBorder)`
align-self:center;
justify-self:start;
opacity:0;
animation: ${fadeIn} 600ms 100ms ease-out forwards;
`

const Gallery = styled(HeroGallery)`
    align-self:start;
    justify-self:stretch;
    grid-column:2;
    grid-row: 2/7;
    @media (max-width: 768px) {
        display:none;
    }
`

const goContact = () => {
    const elm = document.getElementById("contact")
    if (!elm) return
    const offsetTop = elm.offsetTop
    window.scrollTo(0, offsetTop)
}
const link = ""
// const link = "https://jochemvandervalk.nl"
const Home = ({ className }) => {
    const [data, setData] = useState({})
    const [nav, setNav] = useState(false)

    useEffect(() => {
        fetch(link + '/api/?filename=hero_text')
            .then(res => res.json())
            .then(doc => setData(doc.data || {}))
    }, [className])

    useEffect(() => {
        document.body.style.overflow = nav ? "hidden" : "auto"
    }, [nav])


    return <>
        {nav && <Nav setNav={setNav} />}
        <Section className={className} id="home">
            <Layout>
                <Navigation setNav={setNav} />
                {/* <LogoIcon /> */}
                <Moto>{data.subject}</Moto>
                <Textfield>{data.text}</Textfield>
                <Contact onClick={goContact}>Contact me</Contact>

                <Gallery />
            </Layout>
        </Section> </>
}
export default Home