import React from 'react';
import styled from 'styled-components';

import LeftArrow from "../items/left_arrow"
import RightArrow from "../items/right_arrow"

const Container = styled.div`
    display:grid;
    grid-template-columns: max-content 1fr max-content; 
    grid-template-rows:1fr;
    max-width: 150px;
    width:100%;
`
const Wrap = styled.div`
    justify-self:center;
    align-self:center;
    display:flex;
    justify-content:center;
    align-items:center;
    flex-direction: row;
`
const Number = styled.p`
    color: var(--black);
    font-size: var(--text-font);
    font-weight: 500;
`
const Left = styled(LeftArrow)`
justify-self:center;
align-self:center;
width: 20px;
`
const Right = styled(RightArrow)`
justify-self:center;
align-self:center;
width: 20px;
`

const Controller = ({ slider, setSlider, className }) => {

    const editPosition = (i) => setSlider(e => ({ ...e, current: e.current += i, change: i }))
    const canLower = () => slider && slider.current > 1
    const canHigher = () => slider && slider.length > slider.current

    return <Container className={className}>
        <Left onClick={() => editPosition(canLower() ? -1 : 0)} enable={canLower()} />
        <Wrap >
            <Number>{slider ? slider.current : 0}</Number>
            <Number>/</Number>
            <Number>{slider ? slider.length : 0}</Number>
        </Wrap>
        <Right onClick={() => editPosition(canHigher() ? 1 : 0)} enable={canHigher()} />
    </Container>
}
export default Controller