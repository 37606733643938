import React, { useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';

const mainIn = keyframes`
    from {
        // transform: translateX(-100%);
        opacity:0;
    }
    to {
        opacity:1;
        // transform: translateX(0px);
    }
`
const childIn = keyframes`
    from {
        transform: translateY(100%);
    }
    to {
        opacity:1;
        transform: translateY(0px);
    }
`
const Section = styled.section`
position:relative;
// background:#f7f7f7;
height: 600px;
`
const MainHolder = styled.div`
    position:absolute;
    right:0;
    width: calc(100% - 50px);
    overflow:hidden;
    height: 550px;
    display:flex;
    justify-content:center;
    align-items:center;
    & div{
        opacity:0;
        animation: ${mainIn} 1s 100ms cubic-bezier(0, 0, 0.04, 1.01) forwards;
    }
`
const ChildHolder = styled.div`
position:absolute;
left:0;
bottom:0;
width: 225px;
height: 225px;
& div{
    animation: ${childIn} 1s cubic-bezier(0, 0, 0.04, 1.01) forwards;
}
`
const Image = styled.div`
    height:100%;
    width:100%;
    background-image: url(${({ url }) => url});
    background-size:cover;
    background-position:center;
`
const link = ""
// const link = "https://jochemvandervalk.nl"
const ref = "/api/images"
const Hero = ({ className }) => {
    const [data, setData] = useState({})
    useEffect(() => {
        fetch(link + '/api/?filename=hero_images')
            .then(res => res.json())
            .then(doc => setData(doc.data ? doc.data[0].refs : {}))
    }, [className])

    return <Section className={className}>
        <MainHolder>{data[0] && <Image url={link + `${ref}/${data[0]}`} />}</MainHolder>
        <ChildHolder>{data[1] && <Image url={link + `${ref}/${data[1]}`} />}</ChildHolder>
    </Section>
}
export default Hero