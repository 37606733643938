import React from 'react';
import styled from 'styled-components';
import { Arrow } from "./arrow"


const Container = styled(Arrow)`

`

const RightArrow = ({ className, onClick, enable }) => <Container enable={enable} onClick={onClick} className={className} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
    <path xmlns="http://www.w3.org/2000/svg" d="M483.351,209.652L75.003,5.518C58.817-2.574,39.965-1.723,24.576,7.789C9.188,17.301,0,33.778,0,51.867v408.267    c0,18.089,9.188,34.567,24.576,44.078c15.675,9.689,34.545,10.211,50.427,2.271L483.351,302.35    C501.022,293.516,512,275.756,512,256.001S501.022,218.486,483.351,209.652z M465.464,266.572L57.116,470.706    c-5.324,2.663-9.823,0.523-11.51-0.519C43.921,469.146,40,466.081,40,460.134V51.867c0-5.946,3.921-9.011,5.606-10.053    c3.352-2.073,7.547-2.5,11.51-0.519l408.348,204.133c5.899,2.95,6.536,8.376,6.536,10.572    C472,258.197,471.363,263.623,465.464,266.572z" />
</Container>

export default RightArrow